import graph from "../assets/graph2.png"
function Earnings({lang}) {

    let locale = lang.earnings;

    return (
        <>
            <div class="earnings" id="earnings">
                <div class="container">
                    <h2 class="earn-title">
                        <center>{locale.heading}</center>
                    </h2>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <img class="img-graph" src={graph} />
                        </div>
                        <div class="col-12 col-md-6">
                            <ul class="list-earnings">
                                <li><span class="percentage">20%</span><span class="line-to-earning"></span><span class="earnings-type">{locale.element1}</span></li>
                                <li><span class="percentage">20%</span><span class="line-to-earning"></span><span class="earnings-type">{locale.element2}</span></li>
                                <li><span class="percentage">20%</span><span class="line-to-earning"></span><span class="earnings-type">{locale.element3}</span></li>
                                <li><span class="percentage">20%</span><span class="line-to-earning"></span><span class="earnings-type">{locale.element4}</span></li>
                                <li><span class="percentage">20%</span><span class="line-to-earning"></span><span class="earnings-type">{locale.element5}</span></li>
                            </ul>
                            
                            <hr/>
                            
                            <ul class="list-earnings">
                                <li><span class="percentage">100%</span><span class="line-to-earning"></span><span class="earnings-type">{locale.element6}</span></li>
                            </ul>
                        </div>
                    </div>
                <div class="under-earning" dangerouslySetInnerHTML={{ __html: locale.important }} />
                </div>
            </div>
        </>
    );
}

export default Earnings;