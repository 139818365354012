import React, { useState } from "react";
import Logo from "../assets/logo.png";

import pl from "../assets/pl.png";
import gb from "../assets/gb.png";
import ua from "../assets/ua.png";

let Header = ({lang}) => {

    let [toggle, updateToggle] = useState();

    let clickHandle = () => {
        updateToggle(!toggle);
    }

    const style = {
        display: toggle ? "block" : "none"
    };

    let locale = lang.links;
      
    return(
        <>
            <nav class="navbar navbar-expand-lg">
                <div class="container-lg">
                    <a class="navbar-brand" href="#">
                        <img src={Logo} alt="logo" />
                    </a>
                    <button class="navbar-toggler" type="button" onClick={clickHandle}><span class="navbar-toggler-icon"></span></button>
                    
                    <div class="collapse navbar-collapse" id="navbarSupportedContent" style={style}>
                        <ul class="navbar-nav collapse-right mb-2 mb-lg-0">
                            <li class="nav-item"><a class="nav-link active" aria-current="page" href="#benefits">{locale.benefits}</a></li>
                            <li class="nav-item"><a class="nav-link" href="#how-it-works">{locale.howitworks}</a></li>
                            <li class="nav-item"><a class="nav-link" href="#earnings">{locale.earnings}</a></li>
                            <li class="nav-item"><a class="nav-link" href="#registration">{locale.application}</a></li>
                        </ul>
                        <ul class="switchers">
                            <li class="switcher"><a href="/gb"><img src={gb} /></a></li>
                            <li class="switcher slash"><small>/</small></li>
                            <li class="switcher"><a href="./"><img src={pl} /></a></li>
                            <li class="switcher slash"><small>/</small></li>
                            <li class="switcher"><a href="/ua"><img src={ua} /></a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;