function Popup({lang, status, updatePopupOnClick, clientData}) {

    let style;

    if(status){
        style={display: "block"}
    }else{
        style={display: "none"}
    }

    let locale = lang.popup

    return ( 
        <>
            <div className="popup-container" style={style}>
                <div className="popup-overlay" onClick={updatePopupOnClick}></div>
                <div className="popup">
                    <center>
                        <h1>✅</h1>
                        
                        <h3>
                        {locale.thanks1}<span className="span-blue-bold">{clientData.clientName}</span>, {locale.thanks2}. <br />
                        {locale.thanks3} <span className="span-blue-bold">{clientData.dateTime}</span>!
                        </h3>
                    </center>
                </div>
            </div>
        </>
     );
}

export default Popup;