import Arrow1 from "../assets/arrow-1.svg"
import Arrow2 from "../assets/arrow-2.svg"
import of from "../assets/of.png"

function HowItWorks({lang}) {

    return ( 
        <>
            <div className="container how-it-works" id="how-it-works">
                <h2 class="main-header">{lang.howitworks.heading}</h2>
                <h2 class="secondary-header">{lang.howitworks.subheading}</h2>

                <div className="line-to d-md-none d-none d-lg-flex d-xl-flex d-xxl-flex">
                    <div className="line"></div>


                    <ul className="row-items">
                        <li>
                        <div className="top-line"></div>
                            <div className="circle"></div>
                            <div className="btm-line">{lang.howitworks.element1}</div>
                        </li>
                        <li>
                            <div className="top-line"></div>
                            <div className="circle"></div>
                            <div className="btm-line">{lang.howitworks.element2}</div>
                        </li>
                        <li>
                            <div className="top-line"></div>
                            <div className="circle"></div>
                            <div className="btm-line">{lang.howitworks.element3}</div>
                        </li>
                        <li>
                            <div className="top-line"></div>
                            <div className="circle"></div>
                            <div className="btm-line">{lang.howitworks.element4}</div>
                        </li>
                        <li>
                            <div className="top-line"></div>
                            <div className="circle"></div>
                            <div className="btm-line">{lang.howitworks.element5}</div>   
                        </li>
                        <li>
                            <div className="top-line"></div>
                            <div className="circle"></div>
                            <div className="btm-line">{lang.howitworks.element6}</div>    
                        </li>
                        <li>
                            <div className="top-line"></div>
                            <div className="circle">
                                <div className="filled-circle"></div>
                            </div>
                            <div className="btm-line">{lang.howitworks.element7}</div>  
                        </li>
                    </ul>
                </div>


                <div class="line-to fc-ml-mr d-md-block d-block d-lg-none d-xl-none d-xxl-none">
                    <div class="line-mob"></div>
                    
                    <ul class="mobile-line-to">
                        <li>
                            <div class="top-line"></div><div class="circle"></div><div class="btm-line">{lang.howitworks.element1}</div>
                        </li>
                        <li>
                            <div class="top-line"></div><div class="circle"></div><div class="btm-line">{lang.howitworks.element2}</div>
                        </li>
                        <li>
                            <div class="top-line"></div><div class="circle"></div><div class="btm-line">{lang.howitworks.element3}</div></li>
                        <li>
                            <div class="top-line"></div><div class="circle"></div><div class="btm-line">{lang.howitworks.element4}</div>
                        </li>
                        <li>
                            <div class="top-line"></div><div class="circle"></div><div class="btm-line">{lang.howitworks.element5}</div>
                        </li>
                        <li>
                            <div class="top-line"></div><div class="circle"></div><div class="btm-line">{lang.howitworks.element6}</div>
                        </li>
                        <li>
                            <div class="top-line"></div><div class="circle"><div class="filled-circle"></div></div><div class="btm-line">{lang.howitworks.element7}</div>
                        </li>
                    </ul>
                </div>


                <h2 class="secondary-header wdwo">{lang.whatdoweoffer.heading}</h2>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="data-card">
                            <ul className="">
                                <li>
                                    <div className="circle"><div className="filled-circle"></div></div>
                                    <p>{lang.whatdoweoffer.element1}</p>
                                </li>
                                <li>
                                    <div className="circle"><div className="filled-circle"></div></div>
                                    <p>{lang.whatdoweoffer.element2}</p>
                                </li>
                                <li>
                                    <div className="circle"><div className="filled-circle"></div></div>
                                    <p>{lang.whatdoweoffer.element3}</p>
                                </li>
                                <li>
                                    <div className="circle"><div className="filled-circle"></div></div>
                                    <p>{lang.whatdoweoffer.element4}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="data-card">
                            <ul className="">
                                <li>
                                    <div className="circle"><div className="filled-circle"></div></div>
                                    <p>{lang.whatdoweoffer.element5}</p>
                                </li>
                                <li>
                                    <div className="circle"><div className="filled-circle"></div></div>
                                    <p>{lang.whatdoweoffer.element6}</p>
                                </li>
                                <li>
                                    <div className="circle"><div className="filled-circle"></div></div>
                                    <p>{lang.whatdoweoffer.element8}</p>
                                </li>
                                <li>
                                    <div className="circle"><div className="filled-circle"></div></div>
                                    <p>{lang.whatdoweoffer.element9}</p>
                                </li>
                                <li>
                                    <div className="background-text">
                                        {lang.whatdoweoffer.bgtext}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <h2 class="secondary-header wdwo">{lang.whatneeddo.heading}</h2>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <img src={of} alt="" className="what-to-do"/>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="text-w-arrow">
                            <p dangerouslySetInnerHTML={{ __html: lang.whatneeddo.element1 }} />
                            <img src={Arrow1} className="arrow-1" alt="" />
                        </div>
                        <div className="text-w-arrow" style={{textAlign: "right"}}>
                            <img src={Arrow2} className="arrow-2" alt="" />
                            <p dangerouslySetInnerHTML={{ __html: lang.whatneeddo.element2 }} />
                        </div>
                        <div className="text-w-arrow">
                            <p>{lang.whatneeddo.element3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
     );
}

export default HowItWorks;