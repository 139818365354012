import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import model from '../assets/benefit-3.jpeg'

const Benefits = ({lang}) => {

    let locale = lang.benefits;

    return (
        <>
            <div className="benefits-block" id="benefits">
                <center>
                    <h2><b>{locale.heading}</b></h2>
                </center>
                <div className="container">

                    <div className="card-item">
                        <div className="row">
                            <div className="col-12 col-md-4 img-wrapper">
                                <img src={model} alt="" />
                            </div>
                            <div className="col-12 col-md-8 flex-items-container">
                                <div className="row benefits-items">
                                    <div className="col-12 col-md-6">
                                        <div className="item-inside">{locale.element1}</div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="item-inside">{locale.element2}</div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="item-inside">{locale.element3}</div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="item-inside">{locale.element4}</div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="item-inside">{locale.element5}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        /*
                    }
                    <Carousel>
                        <Carousel.Item>
                            <div className="carousel-element">
                                <div className="card-custom">
                                    <div className="row">
                                        <div className="col-12 col-md-5 image-part">
                                            <img src="https://via.placeholder.com/300x300" alt="Main image"/>
                                        </div>
                                        <div className="col-12 col-md-6 text-part">
                                            <h1>Lorem ipsum dolor</h1>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu pulvinar mauris. Pellentesque condimentum quam vulputate, eleifend lectus sit amet, suscipit enim. 
                                                <br /><br />
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu pulvinar mauris. Pellentesque condimentum.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="carousel-element">
                                <div className="card-custom">
                                    <div className="row">
                                        <div className="col-12 col-md-5 image-part">
                                            <img src="https://via.placeholder.com/300x300" alt="Main image"/>
                                        </div>
                                        <div className="col-12 col-md-6 text-part">
                                            <h1>Lorem ipsum dolor</h1>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu pulvinar mauris. Pellentesque condimentum quam vulputate, eleifend lectus sit amet, suscipit enim. 
                                                <br /><br />
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu pulvinar mauris. Pellentesque condimentum.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="carousel-element">
                                <div className="card-custom">
                                    <div className="row">
                                        <div className="col-12 col-md-5 image-part">
                                            <img src="https://via.placeholder.com/300x300" alt="Main image"/>
                                        </div>
                                        <div className="col-12 col-md-6 text-part">
                                            <h1>Lorem ipsum dolor</h1>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu pulvinar mauris. Pellentesque condimentum quam vulputate, eleifend lectus sit amet, suscipit enim. 
                                                <br /><br />
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eu pulvinar mauris. Pellentesque condimentum.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>

                    {
                    */
                    }

                </div>
            </div>
        </>
    );
};

export default Benefits;
